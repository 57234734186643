import dynamic from 'next/dynamic';
import { useState, useEffect, memo } from 'react';

import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box, CardActionArea, Divider, CardContent, Typography, Zoom, Grid, IconButton, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'next-i18next';
import userAPI, { USER_END_POINT } from 'src/api/user';
import MyAvatar from 'src/components/atoms/Avatar';
import { NextImage } from 'src/components/atoms/image';
import NextLink from 'src/components/atoms/link';
import { useRequest, useProfile } from 'src/helpers/use';
import { getAssetUrl, getCourseUrl, getFullnameInstructor, swrImmutableOptions } from 'src/helpers/utils';
import { ICategory, ICourse, IWishlist } from 'src/interfaces';
import theme from 'src/theme';
import { LearningTypeId } from 'src/types/product';
import { useSWRConfig } from 'swr';

import CardAcademicFooter from './cardAcademicFooter';
import CardFooter from './cardFooter';
import {
  DescBoxMobile,
  DescBoxDesktop,
  TitleBoxDesktop,
  CardContainerDesktop,
  CardContainerMobile,
  CardMobileBox,
  FavoriteBoxDesktop,
  IconSkeleton,
  CustomWidthTooltip,
  CertIcon,
  FavoriteBoxMobile,
  BundleImagesBoxDesktop,
  BundleImagesBoxMobile,
  CertCourseBoxDesktop,
  CertCourseBoxMobile,
  TitleBoxMobile,
  Space,
  CardActionContainerMobile,
  CollectHoursBox,
} from './style';

const CourseCardDetail = dynamic(import('./courseCardDetail'));

export type Props = {
  course: ICourse;
  learningPathId?: number;
  learningPathCategory?: ICategory;
  courseCount?: number;
};

type ContentProp = {
  glance?: boolean;
} & Props;

type CardMobileProps = {
  setCanLearn: (value: boolean) => void;
  matches: boolean;
  t: any;
} & Props;

type CardDesktopProps = {} & CardMobileProps;

const getCollecthours = (course: ICourse) =>
  course.bundleCollectHours ? course.bundleCollectHours : course.default_glances?.collect_hours;

const CollectHours = ({ course, isVertical }: Props & { isVertical?: boolean }) => {
  const { t } = useTranslation('common');
  return (
    <CollectHoursBox
      sx={{
        position: 'absolute',
        bottom: { md: 'auto', xs: isVertical ? 'auto' : 6 },
        top: { md: 14, xs: isVertical ? 14 : 'auto' },
        left: { md: 16, xs: isVertical ? 16 : 6 },
        fontSize: { md: '14px', xs: isVertical ? '14px' : '9px' },
        color: 'primary.main',
      }}
    >
      {t('cpd.collect_hour', { hours: getCollecthours(course) })}
    </CollectHoursBox>
  );
};

const TagCertificate = ({ course, isDesktop }: Props & { isDesktop?: boolean }) => {
  const { t } = useTranslation('common');
  if (
    course.isCpdBundle ||
    course.cpd_enabled ||
    course.have_certificate ||
    course.bundle_items?.find((item) => item.have_certificate)
  ) {
    const cert = (
      <>
        <Typography
          className="text-cert"
          variant="body2_deprecated"
          component="div"
          color={theme.palette.commonText?.primary}
        >
          {t('home.course_card_certificate')}
        </Typography>
        <CertIcon className="text-cert" src="/static/icons/cert-white.svg" alt="cert" />
      </>
    );
    if (isDesktop) {
      return <CertCourseBoxDesktop sx={{ position: 'relative', top: '15.5px' }}>{cert}</CertCourseBoxDesktop>;
    }
    return <CertCourseBoxMobile>{cert}</CertCourseBoxMobile>;
  }

  return null;
};

const CourseCardImage = ({
  course,
  matches,
  isVertical = false,
}: Props & { matches?: boolean; isVertical: boolean }) => {
  const [isLoading, setIsLoading] = useState(true);
  const bundles = [...(course.bundle_items || [])].slice(0, 4);
  const totalBundles = bundles.length;

  const renderBundleImages = () => (
    <>
      {isLoading && (
        <Skeleton sx={{ height: 160, position: 'absolute', width: '100%' }} animation="wave" variant="rectangular" />
      )}
      {bundles.map((courseItem, index) => (
        <Box key={courseItem.id} sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <NextImage
            className={`img-bundle-${index + 1}`}
            alt={courseItem.name}
            src={courseItem.highlight_medium_image}
            layout="fill"
            errorImage={`${getAssetUrl('thumbnail-course-card.jpg')}`}
            onLoadingComplete={() => {
              const position = index + 1;
              if (position === totalBundles) {
                setIsLoading(false);
              }
            }}
          />
        </Box>
      ))}
      {totalBundles < 4 &&
        [...Array(4 - totalBundles)].map((_, index) => (
          <Box key={index} className={`img-bundle-${totalBundles + index + 1}`} />
        ))}
    </>
  );

  return course.bundle_items ? (
    matches || isVertical ? (
      <BundleImagesBoxDesktop>{renderBundleImages()}</BundleImagesBoxDesktop>
    ) : (
      <BundleImagesBoxMobile>{renderBundleImages()}</BundleImagesBoxMobile>
    )
  ) : (
    <Box sx={{ position: 'relative', width: '100%', pb: { xs: isVertical ? '59.1%' : '62%', md: '60%', lg: '59.1%' } }}>
      {isLoading && (
        <Skeleton sx={{ height: 160, position: 'absolute', width: '100%' }} animation="wave" variant="rectangular" />
      )}
      <NextImage
        className="img"
        layout="fill"
        alt={course.name}
        src={course.highlight_medium_image || `${getAssetUrl('thumbnail-course-card.jpg')}`}
        errorImage={`${getAssetUrl('thumbnail-course-card.jpg')}`}
        onLoadingComplete={() => setIsLoading(false)}
      />
    </Box>
  );
};

export const ContentDesktop = ({ course, glance }: ContentProp) => {
  const box = (
    <DescBoxDesktop>
      <Typography className="text-card" variant="body2_deprecated" component="div">
        {getFullnameInstructor(course.instructors_info[0])}
      </Typography>
    </DescBoxDesktop>
  );
  return (
    <>
      <TitleBoxDesktop>
        <Typography className="text-card" variant="h4" component="div">
          {course.name}
        </Typography>
      </TitleBoxDesktop>
      <Space />
      {course.instructors_info.length > 1 ? (
        <Box sx={glance ? { my: 0.9 } : undefined}>
          {box}
          <DescBoxDesktop>
            <Typography className="text-card" variant="body2_deprecated" component="div">
              {getFullnameInstructor(course.instructors_info[1])}
            </Typography>
          </DescBoxDesktop>
        </Box>
      ) : (
        <Box sx={glance ? { my: 0.9 } : undefined}>
          {box}
          <DescBoxDesktop>
            <Typography className="desc text-card" variant="overline" component="div">
              {course.instructors_info[0]?.highlight_desc}
            </Typography>
          </DescBoxDesktop>
        </Box>
      )}
    </>
  );
};

const ContentMobile = ({ course, glance }: ContentProp) => {
  const box = (
    <DescBoxMobile>
      <Typography className="text-card" variant="body2_deprecated" component="div">
        {getFullnameInstructor(course.instructors_info[0])}
      </Typography>
    </DescBoxMobile>
  );
  return (
    <>
      <TitleBoxMobile>
        <Typography className="text-card" variant="h4" component="div">
          {course.name}
        </Typography>
      </TitleBoxMobile>
      {course.instructors_info.length > 1 ? (
        <Box sx={glance ? { my: 0.9 } : undefined}>
          {box}
          <DescBoxMobile>
            <Typography className="text-card" variant="body2_deprecated" component="div">
              {getFullnameInstructor(course.instructors_info[1])}
            </Typography>
          </DescBoxMobile>
        </Box>
      ) : (
        <Box sx={glance ? { my: 0.9 } : undefined}>
          {box}
          <DescBoxMobile>
            <Typography className="desc text-card" variant="overline" component="div">
              {course.instructors_info[0]?.highlight_desc}
            </Typography>
          </DescBoxMobile>
        </Box>
      )}
    </>
  );
};

const CardDesktop = ({ course, setCanLearn, t, matches, isVertical }: CardDesktopProps & { isVertical: boolean }) => {
  const instructors_info = [...course?.instructors_info];
  const renderImageInstructors = () => {
    if (instructors_info.length <= 0) {
      return null;
    }
    const box = (
      <Box sx={{ my: course.bundle_items ? '7px' : '7.5px', mx: 2, flexGrow: instructors_info.length > 1 ? 0 : 1 }}>
        <MyAvatar
          sx={{
            border: 'solid 2px white',
            width: 50,
            height: 50,
          }}
          errorImage={`${getAssetUrl('thumbnail-instructor.jpg')}`}
          alt={instructors_info[0].firstname}
          src={instructors_info[0].image_url || `${getAssetUrl('thumbnail-instructor.jpg')}`}
        />
      </Box>
    );
    if (instructors_info.length > 1) {
      return (
        <>
          {box}
          <Box sx={{ my: course.bundle_items ? '7px' : '7.5px', mx: -1.2, flexGrow: 1 }}>
            <MyAvatar
              sx={{
                border: 'solid 2px white',
                width: 50,
                height: 50,
              }}
              errorImage={`${getAssetUrl('thumbnail-instructor.jpg')}`}
              alt={instructors_info[1].firstname}
              src={instructors_info[1].image_url || `${getAssetUrl('thumbnail-instructor.jpg')}`}
            />
          </Box>
        </>
      );
    }
    return box;
  };

  return (
    <CardActionArea>
      <CourseCardImage course={course} matches={matches} isVertical={isVertical} />
      {(course.cpd_enabled && course.default_glances?.collect_hours) ||
      (course.bundleCollectHours && course.bundleCollectHours > 0) ? (
        <CollectHours course={course} isVertical={isVertical} />
      ) : null}
      <Box sx={{ position: 'relative', bottom: 32 }}>
        <Box sx={{ position: 'absolute', display: 'flex', width: '100%' }}>
          {renderImageInstructors()}
          <TagCertificate course={course} isDesktop />
        </Box>
      </Box>
      <CardContent sx={{ height: '210px', display: 'flex', flexDirection: 'column', padding: '16px 16px 24px 16px' }}>
        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <ContentDesktop course={course} />
        </Box>
        {course.is_academic ? (
          <CardAcademicFooter setCanLearn={setCanLearn} course={course} matches={matches} isVertical={isVertical} />
        ) : (
          <CardFooter setCanLearn={setCanLearn} course={course} matches={matches} isVertical={isVertical} />
        )}
      </CardContent>
    </CardActionArea>
  );
};

const MemoizedCardDesktop = memo(CardDesktop);

const CardMobile = ({ course, setCanLearn, matches, t }: CardMobileProps) => (
  <CardActionArea>
    <CardActionContainerMobile>
      <Box>
        <CourseCardImage course={course} isVertical={false} />
        {(course.cpd_enabled && course.default_glances?.collect_hours) ||
        (course.bundleCollectHours && course.bundleCollectHours > 0) ? (
          <CollectHours course={course} />
        ) : null}
      </Box>
      <CardMobileBox>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Box className="box">
            <ContentMobile course={course} />
          </Box>
          <Box>
            <TagCertificate course={course} />
          </Box>
        </Box>
        {course.is_academic ? (
          <CardAcademicFooter setCanLearn={setCanLearn} course={course} matches={matches} />
        ) : (
          <CardFooter setCanLearn={setCanLearn} course={course} matches={matches} />
        )}
      </CardMobileBox>
    </CardActionContainerMobile>
  </CardActionArea>
);

const MemoizedCardMobile = memo(CardMobile);

const CourseCard = ({
  course,
  isVertical = false,
  learningPathId,
  learningPathCategory,
  courseCount,
}: Props & { isVertical?: boolean }) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { profile, isLoading } = useProfile();
  const { data: wishlists, isLoading: wishlistsLoading, isValidating } = useRequest<IWishlist[]>(
    profile ? USER_END_POINT.USER_ME_WISHLIST : null,
    userAPI.fetchUserWishlist,
    swrImmutableOptions,
  );
  const { mutate } = useSWRConfig();
  const [open, setOpen] = useState<boolean>(false);
  const [canLearn, setCanLearn] = useState<boolean>(false);
  const [isComingSoon, setIsComingSoon] = useState<boolean>(false);

  const updateWishlist = async () => {
    if (course.wishlist) {
      mutate(
        USER_END_POINT.USER_ME_WISHLIST,
        { data: wishlists ? wishlists.filter((val) => val.course_id !== course.id) : [] },
        false,
      );
      await userAPI.deleteUserWishlist(course.id);
    } else {
      mutate(
        USER_END_POINT.USER_ME_WISHLIST,
        { data: wishlists ? [...wishlists, { course_id: course.id }] : [] },
        false,
      );
      await userAPI.createUserWishlist({ courseId: course.id });
    }
    mutate(USER_END_POINT.USER_ME_WISHLIST);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const matches = useMediaQuery(`(min-width:${theme.breakpoints.values.lg}px)`);

  const renderIcon = () => {
    if (isLoading || (wishlistsLoading && isValidating)) {
      return <IconSkeleton animation="wave" variant="circular" />;
    }
    if (!profile) return null;
    return (
      <IconButton onClick={updateWishlist}>
        <FavoriteIcon className="icon-heart" sx={course.wishlist ? { color: 'error.dark' } : undefined} />
      </IconButton>
    );
  };

  useEffect(() => {
    if (course.is_academic) {
      const findComingSoon = course.degree_courses?.every((val) => val.coming_soon);
      if (!course.degree_courses.length || findComingSoon) {
        setIsComingSoon(true);
      }
    } else {
      setIsComingSoon(false);
    }
  }, []);

  const link = (() => {
    const isCpd = course.product?.isCpd;
    if (learningPathId && learningPathCategory) {
      return `${getCourseUrl(course)}?category=${
        learningPathCategory?.slug
      }&learningPathId=${learningPathId}&courseCount=${courseCount}`;
    }
    if (canLearn && !isCpd) {
      return `${getCourseUrl(course)}/chapter`;
    }
    if (course.product.redirect_url) {
      return course.product.redirect_url;
    }
    return getCourseUrl(course);
  })();

  return (
    <>
      {matches || isVertical ? ( // desktop
        !isComingSoon ? (
          <CustomWidthTooltip
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            title={
              <CourseCardDetail
                course={course}
                comingSoon={false}
                learningPathId={learningPathId}
                learningPathCategory={learningPathCategory}
                courseCount={courseCount}
              />
            }
            placement="right"
            arrow
            TransitionComponent={Zoom}
            disableFocusListener
          >
            <CardContainerDesktop>
              {!course.bundle_items ? <FavoriteBoxDesktop>{renderIcon()}</FavoriteBoxDesktop> : null}
              <NextLink href={link}>
                <MemoizedCardDesktop
                  course={course}
                  setCanLearn={setCanLearn}
                  t={t}
                  matches={matches}
                  isVertical={isVertical}
                />
              </NextLink>
            </CardContainerDesktop>
          </CustomWidthTooltip>
        ) : (
          <CardContainerDesktop>
            {!course.bundle_items ? <FavoriteBoxDesktop>{renderIcon()}</FavoriteBoxDesktop> : null}
            <MemoizedCardDesktop course={course} setCanLearn={setCanLearn} t={t} matches={matches} isVertical={false} />
          </CardContainerDesktop>
        )
      ) : (
        // mobile
        <CardContainerMobile>
          {!course.bundle_items ? <FavoriteBoxMobile>{renderIcon()}</FavoriteBoxMobile> : null}
          {!isComingSoon ? (
            <NextLink href={link}>
              <MemoizedCardMobile course={course} setCanLearn={setCanLearn} matches={matches} t={t} />
            </NextLink>
          ) : (
            <MemoizedCardMobile course={course} setCanLearn={setCanLearn} matches={matches} t={t} />
          )}
          <Divider className="divide" />
        </CardContainerMobile>
      )}
    </>
  );
};

export default memo(CourseCard);
